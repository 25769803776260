import * as React from "react";
import "./Billing.scss";
import { Panel } from "primereact/panel";
import TimeEntriesList from "../global/timeEntriesList/TimeEntriesList";
import DateRangeSelector from "../global/dateRangeSelector/DateRangeSelector";
import { useRecoilState } from "recoil";
import RecoilStates from "../../../app/models/states/RecoilStates";
import ProjectSelector from "../global/projectSelector/ProjectSelector";

export interface BillingProps {
}

const Billing: React.FC<BillingProps> = (props: BillingProps) => {
    const [userDataState, setUserDataState] = useRecoilState(RecoilStates.currentUserState)
    const [selectedDateRange, setSelectedDateRange] = useRecoilState(RecoilStates.selectedDateRangeState)

    const [dateRange, setDateRange] = React.useState({
        startDate: selectedDateRange[0] ?? null,
        endDate: selectedDateRange[1] ?? null
    });

    React.useEffect(() => {
        const startDate = selectedDateRange[0] ?? null;
        let endDate = selectedDateRange[1];
        // if start date is set but no end date we set it to start date, but end of day
        if (!endDate && startDate) {
            endDate = new Date(startDate.getTime());
            // now but end of day
            endDate.setHours(23, 59, 59, 999);
        }

        setDateRange({
            startDate,
            endDate
        });
    }, [selectedDateRange]);

    return <div className={'Billing p-grid p-col'}>
        <div className={'p-sm-12 p-md-12'}>
            <Panel header={'Welcome ' + (userDataState?.fullname ?? '')}>
                <ProjectSelector />
                <DateRangeSelector />

                <TimeEntriesList
                    startDate={dateRange.startDate}
                    endDate={dateRange.endDate}
                />
            </Panel>
        </div>
    </div>
}

export default Billing;