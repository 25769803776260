// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body{background:#ccc}.ToggleReportsFormatterApp{-webkit-tap-highlight-color:rgba(0,0,0,0);font-family:"Neo Sans Std Light",sans-serif;position:absolute;left:0;right:0;top:0;bottom:0;font-size:14px;color:#000}.ToggleReportsFormatterApp .login-box .col{padding-top:5px;padding-bottom:5px}.ToggleReportsFormatterApp .strong{font-family:"Neo Sans Std",sans-serif}.ToggleReportsFormatterApp td.borderless,.ToggleReportsFormatterApp th.borderless{border:none}.ToggleReportsFormatterApp .border-left{border-left:1px solid #000}.ToggleReportsFormatterApp .border-top{border-top:1px solid #000}`, "",{"version":3,"sources":["webpack://./src/view/components/ToggleReportsFormatterApp.scss"],"names":[],"mappings":"AAAA,KACI,eAAA,CAGJ,2BACI,yCAAA,CACA,2CAAA,CAEA,iBAAA,CACA,MAAA,CACA,OAAA,CACA,KAAA,CACA,QAAA,CAEA,cAAA,CACA,UAAA,CAKA,2CACI,eAAA,CACA,kBAAA,CAGJ,mCACI,qCAAA,CAGJ,kFACI,WAAA,CAGJ,wCACI,0BAAA,CAGJ,uCACI,yBAAA","sourcesContent":["body {\n    background: #CCCCCC;\n}\n\n.ToggleReportsFormatterApp {\n    -webkit-tap-highlight-color: transparent;\n    font-family: 'Neo Sans Std Light', sans-serif;\n\n    position: absolute;\n    left: 0;\n    right: 0;\n    top: 0;\n    bottom: 0;\n\n    font-size: 14px;\n    color: #000000;\n\n    .col {\n    }\n\n    .login-box .col {\n        padding-top: 5px;\n        padding-bottom: 5px;\n    }\n\n    .strong {\n        font-family: \"Neo Sans Std\", sans-serif;\n    }\n\n    td.borderless, th.borderless {\n        border: none;\n    }\n\n    .border-left {\n        border-left: 1px solid #000000;\n    }\n\n    .border-top {\n        border-top: 1px solid #000000;\n    }\n}"],"sourceRoot":""}]);
// Exports
export var ToggleReportsFormatterApp = `ToggleReportsFormatterApp`;
var _1 = `login-box`;
export { _1 as "login-box" };
export var col = `col`;
export var strong = `strong`;
export var borderless = `borderless`;
var _2 = `border-left`;
export { _2 as "border-left" };
var _3 = `border-top`;
export { _3 as "border-top" };
export default ___CSS_LOADER_EXPORT___;
