import * as React from 'react';
import {useEffect} from 'react';
import {Col, Input, Label, Row} from "reactstrap";
import {useRecoilState} from 'recoil';
import {ProjectStates, ProjectStateVOs} from "../../../../app/models/Constants";
import RecoilStates from '../../../../app/models/states/RecoilStates';
import {ProjectStateVO, ToggleVOsProject} from "../../../../app/models/vo/ToggleVOs";
import {ToggleServiceV9} from "../../../../app/services/ToggleServiceV9";

export interface ProjectSelectorProps {
}

const ProjectSelector: React.FC<ProjectSelectorProps> = (props: ProjectSelectorProps) => {

    const [availableProjects, setAvailableProjects] = useRecoilState<ToggleVOsProject[]>(RecoilStates.projectsState);
    const [selectedProjectState, setSelectedProjectState] = useRecoilState<ProjectStateVO>(RecoilStates.selectedProjectStateState);
    const [selectedProject, setSelectedProject] = useRecoilState<ToggleVOsProject>(RecoilStates.selectedProjectState);
    const [selectedWorkSpace, setSelectedWorkSpace] = useRecoilState(RecoilStates.selectedWorkspaceState);

    useEffect(() => {
        // load projects
        if (selectedWorkSpace) {
            // load projects
            ToggleServiceV9.getWorkspaceProjects(selectedWorkSpace.id).then((projects) => {
                setAvailableProjects(projects);
            });
        }
    }, [selectedWorkSpace]);


    const filteredProjects = React.useMemo(() => {
        return availableProjects.filter(
            (project) => {
                switch (selectedProjectState.enum) {
                    case ProjectStates.BOTH:
                        return true;
                    case ProjectStates.ACTIVE:
                        return project.active;
                    case ProjectStates.ARCHIVED:
                        return !project.active;
                    default:
                        return true;
                }
            }
        );
    }, [availableProjects, selectedProjectState]);

    const onProjectChangeHandler = (event: any): void => {
        const target = event.target;
        const value = +(target.type === 'checkbox' ? target.checked : target.value);
        const name = target.name;

        console.log(name, filteredProjects[value]);
        setSelectedProject(filteredProjects[value]);
    };

    const onProjectStateChangeHandler = (event: any): void => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setSelectedProjectState(ProjectStateVOs[value]);
    };

    const renderListItem = (project: ToggleVOsProject, index: number): JSX.Element => {
        const actualHours: number = (project.actual_hours) ? project.actual_hours : 0;
        const estimatedHours: number = (project.estimated_hours) ? project.estimated_hours : 0;

        return <option key={project.id} value={index}>
            {project.name} ({(project.active) ? 'active' : 'inactive'}, {actualHours}h/{estimatedHours}h)
        </option>
    };

    const renderStateItem = (state: ProjectStateVO, index: number): JSX.Element => {
        return <option key={state.enum} value={index}>
            {state.label}
        </option>
    };

    /**
     * Render the project selector
     */
    const renderedProjectSelector = React.useMemo(() => {
        const listEntries = filteredProjects.map(renderListItem);

        return <Col md={{size: 5}}>
            <Input type="select" name="select" id="projectSelect"
                   onChange={onProjectChangeHandler}
                   defaultValue={(selectedProject) ? selectedProject.id.toString() : ''}
            >
                {listEntries}
            </Input>
        </Col>
    }, [filteredProjects]);

    /**
     * Render the project state selector
     */
    const renderedProjectStateSelector = React.useMemo(() => {
        const projectStateEntries = ProjectStateVOs.map(renderStateItem);

        return <Col md={{size: 3}}>
            <Input type="select" name="select" id="projectSelect"
                   onChange={onProjectStateChangeHandler}
                   defaultValue={(selectedProjectState) ? selectedProjectState.enum : ''}
            >
                {projectStateEntries}
            </Input>
        </Col>
    }, [selectedProjectState]);


    const content = React.useMemo(() => {
        return <Row className={'mt-2 mb-2'}>
            <Col md={{offset: 0, size: 4}}>
                <Label for="projectSelect">Selected project:</Label>
            </Col>
            {renderedProjectSelector}
            {renderedProjectStateSelector}
        </Row>;
    }, [availableProjects, selectedProject, selectedProjectState]);

    return content;
}

export default ProjectSelector;