import {atom, RecoilState} from "recoil";
import {SortOrderEnum} from "../enums/SortOrderEnum";
import {SortTypesEnum} from "../enums/SortTypesEnum";
import {
    ProjectStateVO,
    ToggleVOsMe,
    ToggleVOsProject,
    ToggleVOsTimeEntriesSummary,
    ToggleVOsWorkspace
} from "../vo/ToggleVOs";
import {ProjectStates} from "../Constants";
import {LoginVO} from "../vo/LoginVO";
import {recoilPersist} from 'recoil-persist'

const {persistAtom} = recoilPersist()

export default class RecoilStates {
    public static loginVoState: RecoilState<LoginVO> = atom({
        key: 'loginVoState',
        default: {
            email: "",
            password: "",
            rememberMe: false
        },
        effects_UNSTABLE: [persistAtom],
    });
    public static currentUserState: RecoilState<ToggleVOsMe> = atom({
        key: 'currentUserState',
        default: null,
    });

    public static selectedWorkspaceState: RecoilState<ToggleVOsWorkspace> = atom({
        key: 'selectedWorkspaceState',
        default: null,
    });

    public static availableWorkspacesState: RecoilState<ToggleVOsWorkspace[]> = atom({
        key: 'availableWorkspacesState',
        default: [],
    });

    public static projectsState: RecoilState<ToggleVOsProject[]> = atom({
        key: 'projectsState',
        default: [],
    });

    public static projectsFilteredByStateState: RecoilState<ToggleVOsProject[]> = atom({
        key: 'projectsFilteredByStateState',
        default: [],
    });

    public static selectedProjectState: RecoilState<ToggleVOsProject> = atom({
        key: 'selectedProjectState',
        default: null,
    });

    public static selectedProjectStateState: RecoilState<ProjectStateVO> = atom({
        key: 'selectedProjectStateState',
        default: {
            enum: ProjectStates.ACTIVE,
            label: 'Aktiv'
        },
    });

    public static timeEntriesSummariesState: RecoilState<ToggleVOsTimeEntriesSummary[]> = atom({
        key: 'timeEntriesSummariesState',
        default: [],
    });

    public static timeEntriesSortedBy: RecoilState<{ value: SortTypesEnum, order: SortOrderEnum }> = atom({
        key: 'timeEntriesSortedBy',
        default: {value: SortTypesEnum.DESCRIPTION, order: SortOrderEnum.DESC},
    });

    // selected dates for the date range selector
    public static selectedDateRangeState: RecoilState<Date | Date[]> = atom({
        key: 'selectedDateRangeState',
        default: []
    });
}