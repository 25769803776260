import * as React from 'react';
import "./DateRangeSelector.scss"
import {Label} from "reactstrap";
import {Calendar} from "primereact/calendar";
import {useRecoilState} from "recoil";
import RecoilStates from "../../../../app/models/states/RecoilStates";
import {FormEvent} from "primereact/ts-helpers";

export interface DateRangeSelectorProps {
}

const DateRangeSelector: React.FC<DateRangeSelectorProps> = (props: DateRangeSelectorProps) => {
    const [dates, setDates] = useRecoilState(
        RecoilStates.selectedDateRangeState
    );

    const onDateRangeChangeHandler = (event: FormEvent<(Date | null)[]>): void => {
        let dates = event.value ?? [];
        
        // if we have a start date only we need an end date too (same day)
        if (dates.length === 1) {
            dates.push(new Date(dates[0].getTime()));
        }

        setDates(dates)
    }

    return <div className={'DateRangeSelector p-grid p-col'}>
        <Label
            className={'p-col-4'}
            for="projectSelect"
        >
            Filter by date range (currently for last 355 days):
        </Label>
        <Calendar
            className={'p-col-8 p-col-nogutter'}
            selectionMode="range"
            minDate={new Date(new Date().setDate(new Date().getDate() - 355))}
            maxDate={new Date(new Date().setDate(new Date().getDate() + 1))}
            value={dates}
            onChange={onDateRangeChangeHandler}
            showButtonBar
            hideOnRangeSelection
        >
        </Calendar>
    </div>;
}

export default DateRangeSelector;