import * as React from 'react';
import {render} from 'react-dom';

import {RecoilRoot} from 'recoil';
import './scss/styles.scss';
import ToggleReportsFormatterApp from './view/components/ToggleReportsFormatterApp';
import {HtmlAttributesUtil} from '@dashart/d_utilz_typescript';
import {ToggleServiceV9} from './app/services/ToggleServiceV9';

const domElement: HTMLElement = document.getElementById('ToggleReportsFormatter') as HTMLElement;
const apiURI = HtmlAttributesUtil.getAttributeAsText(domElement, 'data-api-uri', 'api.track.toggl.com');
const apiCorsProxyKey = HtmlAttributesUtil.getAttributeAsText(domElement, 'data-api-cors-proxy-key', 'test');

ToggleServiceV9.configure(apiURI, apiCorsProxyKey);

const ToggleReportsFormatter = <RecoilRoot>
    <ToggleReportsFormatterApp />
</RecoilRoot>;

render(
    ToggleReportsFormatter,
    domElement
);
