import * as React from "react";
import "./Reports.scss"

export interface ReportsProps {

}

export class Reports extends React.Component<ReportsProps, {}> {
    render(): React.ReactNode {
        return <div className='Reports'>
            Reports
        </div>
    }
}