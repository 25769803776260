import { ProgressSpinner } from "primereact/progressspinner";
import * as React from "react";
import { Redirect } from "react-router";
import { Routes } from "../../../app/models/Routes";

export interface InitScreenProps {

}

const InitScreen: React.FunctionComponent<InitScreenProps> = (props: InitScreenProps) => {

    const [redirectTo, setRedirectTo] = React.useState(null);

    React.useEffect(() => {
        const tid = setTimeout(
            () => {
                setRedirectTo(Routes.login);
            },
            1000,
        );
        return () => {
            clearTimeout(tid);
        }
    }, [])

    return <div className='InitScreen p-col-12 p-grid'>
        {redirectTo ? <Redirect to={redirectTo} /> : <ProgressSpinner />}
    </div>;
}

export default InitScreen;