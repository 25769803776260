import axios from "axios";
import moment from "moment";
import { LoginVO } from "../models/vo/LoginVO";
import { ToggleVOsMe, ToggleVOsProject, ToggleVOsTag, ToggleVOsTimeEntriesSummary, ToggleVOsWorkspace } from "../models/vo/ToggleVOs";

/**
 * TimeEntryFilter interface for defining time entry filter options.
 */
interface TimeEntryFilterInterface {
  collapse?: boolean;
  date_format?: string;
  display_mode?: string;
  hide_rates?: boolean;
  time_format?: string;
  /**
   * Whether the time entry is set as billable, optional, premium feature.
   */
  billable?: boolean;

  /**
   * Client IDs for filtering. Use [null] to filter records with no clients.
   */
  client_ids?: number[];

  /**
   * Description for filtering.
   */
  description?: string;

  /**
   * End date for the time entry, should be greater than start date.
   */
  end_date?: string;

  /**
   * Attribute used internally.
   */
  first_id?: number;

  /**
   * Attribute used internally.
   */
  first_row_number?: number;

  /**
   * Attribute used internally.
   */
  first_timestamp?: number;

  /**
   * Group IDs for filtering.
   */
  group_ids?: number[];

  /**
   * Whether time entries should be grouped.
   */
  grouped?: boolean;

  /**
   * Whether amounts should be hidden.
   */
  hide_amounts?: boolean;

  /**
   * Max duration in seconds for filtering, should be greater than min_duration_seconds.
   */
  max_duration_seconds?: number;

  /**
   * Min duration in seconds for filtering, should be less than max_duration_seconds.
   */
  min_duration_seconds?: number;

  /**
   * Field to order by. Can be "date", "user", "duration", "description", or "last_update".
   */
  order_by?: string;

  /**
   * Order direction. Can be ASC or DESC.
   */
  order_dir?: string;

  /**
   * Number of items per page.
   */
  page_size?: number;

  /**
   * Attribute used internally.
   */
  postedFields?: string[];

  /**
   * Project IDs for filtering. Use [null] to filter records with no projects.
   */
  project_ids?: number[];

  /**
   * Whether time should be rounded, default from workspace settings.
   */
  rounding?: number;

  /**
   * Rounding minutes value. Should be 0, 1, 5, 6, 10, 12, 15, 30, 60, or 240.
   */
  rounding_minutes?: number;

  /**
   * Attribute used internally.
   */
  startTime?: string;

  /**
   * Start date for the time entry, should be less than end date.
   */
  start_date?: string;

  /**
   * Tag IDs for filtering. Use [null] to filter records with no tags.
   */
  tag_ids?: number[];

  /**
   * Task IDs for filtering. Use [null] to filter records with no tasks.
   */
  task_ids?: number[];

  /**
   * Time entry IDs for filtering.
   */
  time_entry_ids?: number[];

  /**
   * User IDs for filtering.
   */
  user_ids?: number[];
}

export class ToggleServiceV9 {

  private static _apiToken: string;

  private static _apiURI: string;
  private static _apiCorsProxyKey: string;
  private static _defaultBaseUrl: string = "https://api.track.toggl.com/api/v9/";
  private static _reportsBaseUrl: string = "https://api.track.toggl.com/reports/api/v3/";

  /**
   * Configures the service with the base domain
   * @param apiURI 
   * @param apiCorsProxyKey
   */
  public static configure(apiURI: string, apiCorsProxyKey: string): void {
    this._apiURI = apiURI;
    this._defaultBaseUrl = `${apiURI}/api/v9/`;
    this._reportsBaseUrl = `${apiURI}/reports/api/v3/`;
    this._apiCorsProxyKey = apiCorsProxyKey;
  }

  /**
   * Returns the API token if the user is logged in
   * @returns 
   */
  private static getAuthHeaders(): { Authorization: string, 'x-cors-api-key': string } {
    return {
      Authorization: `Basic ${btoa(this._apiToken + ':api_token')}`,
      'x-cors-api-key': this._apiCorsProxyKey
    };
  }

  /**
   * Extracts the data from the API response, throws an error if the response is empty
   * @param response 
   * @returns 
   */
  private static handleApiResponse(response: any): any {
    if (response && response.data) {
      return response.data;
    }
    throw new Error('API response is empty or has no data');
  }

  /**
   * Logs in the user and stores the API token for further requests
   * @param loginVO 
   * @returns 
   */
  static async login(loginVO: LoginVO): Promise<ToggleVOsMe> {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Basic ${btoa(loginVO.email + ':' + loginVO.password)}`,
      'x-cors-api-key': this._apiCorsProxyKey,
    }
    const response = await axios.get(this._defaultBaseUrl + "me", {
      headers: headers,
    });

    const data = this.handleApiResponse(response);
    if (data.api_token) {
      this._apiToken = data.api_token;
    }

    // fetch all workspaces and store them in the user data
    data.workspaces = await this.getWorkspaces();

    return data;
  }

  /**
   * Logs out the user by clearing the API token
   */
  static async logout(): Promise<void> {
    this._apiToken = null;
  }

  /**
   * Fetches all workspaces for the authenticated user
   * @returns 
   */
  static async getWorkspaces(): Promise<ToggleVOsWorkspace[]> {
    const response = await axios.get(this._defaultBaseUrl + "workspaces", {
      headers: this.getAuthHeaders(),
    });

    return this.handleApiResponse(response);
  }

  /**
   * Fetches all projects for a workspace
   * @param workspaceId 
   * @returns 
   */
  static async getWorkspaceProjects(workspaceId: number): Promise<ToggleVOsProject[]> {
    return this.getWorkspaceAllProjects(workspaceId);
  }

  /**
   * Fetches all tags for a workspace
   * @param workspaceId 
   * @returns 
   */
  static async getWorkspaceTags(workspaceId: number): Promise<ToggleVOsTag[]> {
    const response = await axios.get(this._defaultBaseUrl + `workspaces/${workspaceId}/tags`, {
      headers: this.getAuthHeaders(),
    });

    return this.handleApiResponse(response);
  }

  /**
   * Fetches all time entries for a workspace and project
   * @param workspaceId 
   * @param projectId 
   * @param roundTimes 
   * @returns 
   */
  static async getTimeEntries(workspaceId: number, projectId: number, roundTimes: boolean, startDate: Date, endDate: Date): Promise<ToggleVOsTimeEntriesSummary[]> {
    const params2: TimeEntryFilterInterface = {
      collapse: true,
      date_format: "MM/DD/YYYY",
      display_mode: "date_and_time",
      //duration_format:	"improved"
      start_date: moment(startDate).format("YYYY-MM-DD"),
      end_date: moment(endDate).format("YYYY-MM-DD"),
      first_row_number: null,
      grouped: false,
      hide_amounts: true,
      hide_rates: true,
      rounding: roundTimes ? 1 : 0,
      order_by: "date",
      order_dir: "asc",
      project_ids: [projectId],
      // get date in format yyyy-mm-dd
      time_format: "HH:mm" // 'decimal' possible?
    };

    const entries = await this.getPaginatedTimeEntries(workspaceId, projectId, params2);

    // Pagination handling omitted since v9 supports pagination differently and should be handled separately
    return entries as any;
  }

  /**
   * Fetches all time entries for a workspace and project
   * @param workspaceId 
   * @param projectId 
   * @param params 
   * @returns 
   */
  private static async getPaginatedTimeEntries(workspaceId: number, projectId: number, params: TimeEntryFilterInterface): Promise<ToggleVOsTimeEntriesSummary[]> {
    let results: ToggleVOsTimeEntriesSummary[] = [];

    // while response headers contain X-Next-Row-Number, keep fetching, initial value is null
    let nextRowNumber: number | null = null;
    do {
      const response = await axios.post(
        `${this._reportsBaseUrl}workspace/${workspaceId}/search/time_entries`,
        { ...params, first_row_number: nextRowNumber },
        {
          headers: this.getAuthHeaders(),
        }
      );

      const data: any[] = this.handleApiResponse(response) ?? [];
      results = [...results, ...data];

      // the idiots from toggle did not extended the "Access-Control-Expose-Headers" header with the custom headers x-values defined in the API
      // based on this the headers are not accessible in the frontend
      // so we have to find a workaround to get the next row number

      // access the last element of the data array, take the "row_number" and add 1
      if (data.length > 0) {
        nextRowNumber = data[data.length - 1].row_number + 1;
      } else {
        nextRowNumber = null;
      }

    } while (nextRowNumber);

    return results;
  }

  // New helper to fetch all pages for methods that require pagination
  private static async fetchAllPages<T>(url: string, workspaceId: number, params: any = {}): Promise<T[]> {
    let allItems: T[] = [];
    let page = 1;
    let keepFetching = true;

    // Assuming v9 API supports pagination in a similar way, adjust as needed once documentation is available
    do {
      const response = await axios.get(
        url.replace('{workspaceId}', `${workspaceId}`), {
        headers: this.getAuthHeaders(),
        params: { ...params, page: page },
      }
      );

      const data = this.handleApiResponse(response);
      allItems = allItems.concat(data);

      page++;
      keepFetching = data.length > 0; // Continue if we got data, indicating there might be more
    } while (keepFetching);

    return allItems;
  }

  /**
   * Fetches all active projects for a workspace
   * @param workspaceId 
   * @returns 
   */
  static async getWorkspaceActiveProjects(workspaceId: number): Promise<ToggleVOsProject[]> {
    const params = { active: 'true' };
    return this.fetchAllPages<ToggleVOsProject>(this._defaultBaseUrl + "workspaces/{workspaceId}/projects", workspaceId, params);
  }

  /**
   * Fetches all inactive projects for a workspace
   * @param workspaceId 
   * @returns 
   */
  static async getWorkspaceInactiveProjects(workspaceId: number): Promise<ToggleVOsProject[]> {
    const params = { active: 'false' };
    return this.fetchAllPages<ToggleVOsProject>(this._defaultBaseUrl + "workspaces/{workspaceId}/projects", workspaceId, params);
  }

  /**
   * Fetches all projects for a workspace, regardless of their active status
   * @param workspaceId 
   * @returns 
   */
  static async getWorkspaceAllProjects(workspaceId: number): Promise<ToggleVOsProject[]> {
    // Assuming that no 'active' param fetches all projects
    return this.fetchAllPages<ToggleVOsProject>(this._defaultBaseUrl + "workspaces/{workspaceId}/projects", workspaceId);
  }

}