import * as React from "react";
import "./DashBoard.scss"

export interface DashBoardProps {

}

export class DashBoard extends React.Component<DashBoardProps, {}> {
    render(): React.ReactNode {
        return <div className='DashBoard'>
            DASHBOARD
        </div>
    }
}