import * as React from "react";
import "./Settings.scss"

export interface SettingsProps {

}

export class Settings extends React.Component<SettingsProps, {}> {
    render(): React.ReactNode {
        return <div className='Settings'>
            Settings
        </div>
    }
}