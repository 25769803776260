import * as React from "react";
import {Button, Card, CardTitle, Col, FormGroup, Input, Label, Row} from "reactstrap";
import { LoginVO } from "../../../../app/models/vo/LoginVO";
import { useRecoilState } from "recoil";
import RecoilStates from "../../../../app/models/states/RecoilStates";

export interface LoginBoxProps {
    loading: boolean;
    login: (loginVO: LoginVO) => void;
}

const LoginBox: React.FC<LoginBoxProps> = (props: LoginBoxProps) => {

    const [state, setState] = useRecoilState<LoginVO>(RecoilStates.loginVoState);

    const onClearHandler = (): void => {
        setState({
            email: "",
            password: "",
            rememberMe: false
        })
    };

    const onLoginHandler = (): void => {
        console.log("onLoginHandler", state);
        props.login(state);
    };

    const onChangeHandler = (event: any): void => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setState({
            ...state,
            [name]: value
        });
    };

    return <Row className={'login-box'}>
        <Col md={{ offset: 3, size: 6 }}>
            <Card body>
                <CardTitle>
                    Login
                </CardTitle>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label for="userName">Email</Label>
                            <Input name="email" id="userName" value={state.email}
                                onChange={onChangeHandler} />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label for="password">Password</Label>
                            <Input name="password" id="password" type={'password'} value={state.password}
                                onChange={onChangeHandler} />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup check>
                            <Label check>
                                <Input name="rememberMe" type="checkbox" value={state.rememberMe ? 'on' : ''}
                                    onChange={onChangeHandler} />{' '}
                                Remember me
                            </Label>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button className={'col-md-12'} onClick={onClearHandler}>Cancel</Button>
                    </Col>
                    <Col>
                        <Button color={'primary'} className={'col-md-12'}
                            onClick={onLoginHandler}>Login</Button>
                    </Col>
                </Row>
            </Card>
        </Col>
    </Row>
}

export default LoginBox;