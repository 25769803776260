import * as moment from "moment";

export class TimeUtils {
    /**
     * Transform ms to hours.
     * If rounded is set to true, it will be rounded by quarters.
     * @param ms
     * @param rounded
     */
    static msToHoursDecimal(ms: number, rounded: boolean = false): number {
        const momentDuration = moment.duration(ms, "milliseconds");
        let val: number = momentDuration.asHours();
        if (rounded) {
            val = (Math.ceil(val * 4) / 4);
        }
        return val;
    }

    static secondsToHoursDecimal(seconds: number, rounded: boolean = false): number {
        return TimeUtils.msToHoursDecimal(seconds * 1000, rounded);
    }

    /**
     * Format value as HH:mm:ss / HHH:mm:ss
     * @param duration
     */
    static msToHHmmss(duration: number): string {
        const momentDuration = moment.duration(duration, "milliseconds");
        const momentDurationAsUtc = moment.utc(momentDuration.asMilliseconds());
        // for mm / ss we could use standard formatting
        const mmss = momentDurationAsUtc.format("mm:ss");

        // for hours we have to use duration hours, because otherwise, we can not display more than 24 hours
        // because everything bigger than 24 will be split to days
        let h = `${Math.floor(momentDuration.asHours())}`;
        while (h.length < 2) {
            h = `0${h}`;
        }
        return `${h}:${mmss}`
    }

    static timeRangeStartDateToTimestamp(startDate: Date): string {
        return moment(startDate).hours(0).minutes(0).second(0).unix().toString();
    }

    static timeRangeStartTimestampToDate(startDate: string): Date {
        return moment.unix(parseInt(startDate)).toDate();
    }

    static timeRangeEndDateToTimestamp(endDate: Date): string {
        return moment(endDate).hours(23).minutes(59).second(59).unix().toString();
    }

    static timeRangeEndTimestampToDate(endDate: string): Date {
        return moment.unix(parseInt(endDate)).toDate();
    }
}