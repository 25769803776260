import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Panel } from "primereact/panel";
import * as React from "react";
import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { TimeUtils } from "../../../app/helper/TimeUtils";
import { ReportingTimeEntry } from "../../../app/models/TogglInterfaces";
import RecoilStates from "../../../app/models/states/RecoilStates";
import { ToggleVOsTimeEntry } from "../../../app/models/vo/ToggleVOs";
import { ToggleServiceV9 } from "../../../app/services/ToggleServiceV9";
import ProjectSelector from "../global/projectSelector/ProjectSelector";
import "./ProjectsAndEntries.scss";

export interface ProjectsAndEntriesProps {
}

const ProjectsAndEntries: React.FC<ProjectsAndEntriesProps> = (props: ProjectsAndEntriesProps) => {

    const [selectedWorkSpace, setSelectedWorkSpace] = useRecoilState(RecoilStates.selectedWorkspaceState);
    const [selectedProject, setSelectedProject] = useRecoilState(RecoilStates.selectedProjectState);
    const [selectedEntries, setSelectedEntries] = React.useState(null);
    const [entries, setEntries] = React.useState<ToggleVOsTimeEntry[]>([]);
    const [selectedDateRange, setSelectedDateRange] = useRecoilState(RecoilStates.selectedDateRangeState);

    // take care of the date range
    useEffect(() => {
        let rangeChanged = false;

        // start date if not set now minus 355 days
        let startDate = selectedDateRange[0];
        if (!startDate) {
            startDate = new Date();
            startDate.setDate(startDate.getDate() - 355);
            rangeChanged = true;
        }
        // if no end date set it to start date, but end of day
        let endDate = selectedDateRange[1];
        if (!endDate) {
            endDate = new Date(startDate.getTime());
            // now but end of day
            endDate.setHours(23, 59, 59, 999);
            rangeChanged = true;
        }

        if (rangeChanged) {
            setSelectedDateRange([startDate, endDate]);
        }
    }, [selectedDateRange]);

    useEffect(() => {
        fetchData();
    }, [selectedProject, selectedDateRange]);

    // load entries
    const fetchData = async () => {
        if (!selectedDateRange || Array.isArray(selectedDateRange) && selectedDateRange.length !== 2) {
            return;
        }

        if (!selectedProject || !selectedWorkSpace) {
            return;
        }

        const summarizedEntries = await ToggleServiceV9.getTimeEntries(
            selectedWorkSpace.id,
            selectedProject.id,
            false,
            selectedDateRange[0],
            selectedDateRange[1],
        );

        const entriesGrouped = summarizedEntries.map((entry) => {
            return entry.time_entries ?? [];
        });

        // flatten the array
        const flatEntries: ToggleVOsTimeEntry[] = entriesGrouped.reduce((acc, val) => acc.concat(val), []);

        setEntries(flatEntries);
    };

    const durationTemplate = (rowData: ReportingTimeEntry, column) => {
        return <div>
            {TimeUtils.msToHHmmss(rowData.dur)}
        </div>;
    }

    const durationSummaryTemplate = () => {
        let sum = 0;
        entries.map((entry) => {
            sum += entry.seconds * 1000;
        });
        return <div>
            {TimeUtils.msToHHmmss(sum)}
        </div>;
    }

    return <div className='ProjectsAndEntries p-grid p-col-nogutter'>
        <div className={'p-sm-12 p-md-12'}>
            <Panel>
                <ProjectSelector />
                {/* <DateRangeSelector/> */}

                <DataTable
                    value={entries}
                    resizableColumns={true}
                    columnResizeMode="fit"
                    selection={selectedEntries}
                    onSelectionChange={(e) => {
                        setSelectedEntries(e.value)
                    }}>
                    <Column selectionMode="multiple" style={{ width: '3em' }} />
                    <Column field="description" header="Beschreibung" sortable={true} footer={'wtf'} />
                    <Column field="dur" header="Dauer" body={durationTemplate} style={{ width: '20%' }}
                        sortable={true} footer={durationSummaryTemplate()} />
                    <Column field="tags" header="Tags" style={{ width: '20%' }} sortable={true} />
                </DataTable>
            </Panel>
        </div>
    </div>
}

export default ProjectsAndEntries;