import "./MainMenu.scss"
import * as React from "react";
import {useLocation} from "react-router-dom";
import {Menubar} from "primereact/menubar";
import {Button} from "primereact/button";
import {Routes} from "../../../../app/models/Routes";
import {useRecoilState} from "recoil";
import RecoilStates from "../../../../app/models/states/RecoilStates";

export interface MainMenuProps {
    tryLogout: () => void,
}

const MainMenu: React.FunctionComponent<MainMenuProps> = (props: MainMenuProps) => {
    const location = useLocation();

    const [userDataState, setUserDataState] = useRecoilState(RecoilStates.currentUserState);
    const [selectedWorkSpace, setSelectedWorkSpace] = useRecoilState(RecoilStates.selectedWorkspaceState);
    const [availableWorkspaces, setAvailableWorkspaces] = useRecoilState(RecoilStates.availableWorkspacesState);

    const logout = async () => {
        props.tryLogout();
    };

    const selectWorkSpace = (menuEvent) => {
        setSelectedWorkSpace(menuEvent.item.workspace);
    }

    if (!userDataState) {
        return null;
    }

    const menuItems = [
        {
            label: 'Dashboard',
            url: '#' + Routes.dashboard,
            //command: navigateToRoute,
            className: location.pathname.startsWith(Routes.dashboard) ? "p-menuitem-active" : ''
        }, {
            label: 'Projects and Entries',
            url: '#' + Routes.projectsAndEntries,
            className: location.pathname.startsWith(Routes.projectsAndEntries) ? "p-menuitem-active" : ''
        }, {
            label: 'Billing',
            url: '#' + Routes.billing,
            className: location.pathname.startsWith(Routes.billing) ? "p-menuitem-active" : ''
        }, {
            label: 'Reports',
            url: '#' + Routes.reports,
            className: location.pathname.startsWith(Routes.reports) ? "p-menuitem-active" : ''
        }, {
            label: 'Settings',
            url: '#' + Routes.settings,
            className: location.pathname.startsWith(Routes.settings) ? "p-menuitem-active" : ''
        }, {
            label: 'Workspace: ' + (selectedWorkSpace?.name ?? '-'),
            icon: 'pi pi-users',
            items: (availableWorkspaces ?? []).map((workspace) => {
                return {
                    label: workspace.name,
                    workspace: workspace,
                    command: selectWorkSpace
                }
            })
        }
    ]

    return <div className={'MainMenu p-col p-col-nogutter'}>
        <Menubar
            model={menuItems}
            end={<Button label="Logout" icon="pi pi-power-off" style={{marginLeft: 4}} onClick={logout}/>}
        />
    </div>
}

export default MainMenu;