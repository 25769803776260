import "./ToggleReportsFormatterApp.scss"
import 'bootstrap/dist/css/bootstrap.min.css';
import * as React from "react";
import {useRecoilState} from 'recoil';
import RecoilStates from '../../app/models/states/RecoilStates';
import {LoginVO} from "../../app/models/vo/LoginVO";
import {ToggleVOsMe} from "../../app/models/vo/ToggleVOs";
import {ToggleServiceV9} from "../../app/services/ToggleServiceV9";
import LoginBox from './global/LoginBox/LoginBox';
import {HashRouter, Redirect, Route, Switch} from "react-router-dom";
import {Routes} from "../../app/models/Routes";
import ProtectedRoute from "./global/protectedRoute/ProtectedRoute";
import {Settings} from "./settings/Settings";
import {Reports} from "./reports/Reports";
import MainMenu from "./global/mainMenu/MainMenu";
import InitScreen from "./initScreen/InitScreen";
import ProjectsAndEntries from "./projectsAndEntries/ProjectsAndEntries";
import {DashBoard} from "./dashBoard/DashBoard";
import Billing from "./billing/Billing";

export interface ToggleReportsFormatterAppProps {
}

const ToggleReportsFormatterApp: React.FC<ToggleReportsFormatterAppProps> = (props: ToggleReportsFormatterAppProps) => {

    const [appState, setAppState] = React.useState({
        processingLogin: false,
        hasLoadError: false,
        loadErrorMessage: '',
        redirectPathOnAuthentication: null,
    });

    const [userDataState, setUserDataState] = useRecoilState<ToggleVOsMe>(RecoilStates.currentUserState);
    const [selectedWorkSpace, setSelectedWorkSpace] = useRecoilState(RecoilStates.selectedWorkspaceState);

    // load works spaces on user login
    React.useEffect(() => {
        if (userDataState) {
            ToggleServiceV9.getWorkspaces().then((workspaces) => {
                if (workspaces.length > 0) {
                    setSelectedWorkSpace(workspaces[0]);
                }
            });
        }
    }, [userDataState]);

    const login = async (loginVO: LoginVO) => {
        setAppState((appState) => ({
            ...appState,
            processingLogin: true
        }));

        const resultMeData: ToggleVOsMe = await ToggleServiceV9.login(loginVO);

        if (resultMeData) {
            setUserDataState(resultMeData);
        }

        setAppState((appState) => ({
            ...appState,
            processingLogin: false,
            isLoggedIn: resultMeData !== null
        }));
    };

    const logout = async () => {
        await ToggleServiceV9.logout();

        setUserDataState(null)
    };

    const setRedirectPathOnAuthentication = (path: string) => {
        setAppState((prevState) => {
            return {
                ...prevState,
                redirectPathOnAuthentication: path
            }
        })
    }

    return <div className='ToggleReportsFormatterApp p-col p-col-nogutter'>
        <HashRouter>
            <MainMenu
                tryLogout={logout}
            />
            <div className={'col-12'}>
                Timetracking area
            </div>
            <div className={'p-col-12'}>

                <Switch>
                    <Route exact path={'/'}>
                        {
                            !userDataState ?
                                <InitScreen/> :
                                <Redirect
                                    to={{
                                        pathname: appState.redirectPathOnAuthentication ?? Routes.billing
                                    }}
                                />
                        }
                    </Route>

                    <Route exact path={[Routes.login]}>
                        {
                            !userDataState ?
                                <LoginBox login={login} loading={appState.processingLogin}/> :
                                <Redirect
                                    to={{
                                        pathname: appState.redirectPathOnAuthentication ?? Routes.billing
                                    }}
                                />
                        }
                    </Route>

                    <ProtectedRoute
                        isAuthenticated={userDataState !== null}
                        authenticationPath={'/login'}
                        redirectPathOnAuthentication={appState.redirectPathOnAuthentication || ''}
                        setRedirectPathOnAuthentication={setRedirectPathOnAuthentication}
                    >
                        <>
                            <Switch>
                                <Route exact path="/">
                                    <Redirect
                                        to={{
                                            pathname: Routes.dashboard
                                        }}
                                    />
                                </Route>
                                <Route path={Routes.dashboard} component={DashBoard}/>
                                <Route path={Routes.projectsAndEntries} component={ProjectsAndEntries}/>
                                <Route path={Routes.billing} component={Billing}/>
                                <Route path={Routes.reports} component={Reports}/>
                                <Route path={Routes.settings} component={Settings}/>
                            </Switch>
                        </>
                    </ProtectedRoute>
                </Switch>
            </div>
        </HashRouter>
    </div>
}

export default ToggleReportsFormatterApp;
