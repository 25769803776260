import * as React from "react";
import {Col, Input, Label, Row} from "reactstrap";
import {SortOrderEnum} from "../../../../app/models/enums/SortOrderEnum";
import {SortTypesEnum} from "../../../../app/models/enums/SortTypesEnum";

export interface SortOnFilterProps {
    sortedBy: SortTypesEnum;
    sortedAsc: boolean;

    onSortBySelected: (propertyName: SortTypesEnum, asc: boolean) => void;
}

const SortOnFilter: React.FC<SortOnFilterProps> = (props: SortOnFilterProps) => {

    const onSortOnSelected = (event: any): void => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        props.onSortBySelected(value, props.sortedAsc);
    };

    const onOrderSelected = (event: any): void => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        props.onSortBySelected(props.sortedBy, (value == SortOrderEnum.ASC));
    };

    return <Col md={{offset: 0, size: 12}}>
        <Row>
            <Col md={{offset: 0, size: 4}}>
                <Label for="sortBy">Sort by:</Label>
            </Col>
            <Col md={{size: 5}}>
                <Input type="select" name="select" id="sortBy"
                       value={(props.sortedBy) ? props.sortedBy : SortTypesEnum.DESCRIPTION}
                       onChange={onSortOnSelected}>
                    <option key={'opt-1'} value={SortTypesEnum.DESCRIPTION}>Beschreibung</option>
                    <option key={'opt-2'} value={SortTypesEnum.DURATION}>Dauer</option>
                    <option key={'opt-3'} value={SortTypesEnum.START_DATE}>Startdatum</option>
                    <option key={'opt-4'} value={SortTypesEnum.END_DATE}>Enddatum</option>
                </Input>
            </Col>
            <Col md={{size: 3}}>
                <Input type="select" name="select" id="order"
                       value={(props.sortedAsc) ? SortOrderEnum.ASC : SortOrderEnum.DESC}
                       onChange={onOrderSelected}>
                    <option key={'opt-0'} value={SortOrderEnum.DESC}>Absteigend</option>
                    <option key={'opt-1'} value={SortOrderEnum.ASC}>Aufsteigend</option>
                </Input>
            </Col>
        </Row>
    </Col>;
}

export default SortOnFilter;
